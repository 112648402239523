<template>
   <div>
    <v-banner
        single-line
        class="banner"
    >
      <div class="h5-carousel-new carousel-banner">
         <v-img src="https://h5.ophyer.cn/official_website/banner/fangdichanH5.png" width="100%" height="100%" contain>
             <template v-slot:placeholder><img-placeholder></img-placeholder></template>
         </v-img>   
         <div class="h5-banner-info">
             <div class="width-per-100">
               <div class="h5-banner-title font-size-h5banner-title">H5微楼书</div>
             </div>
         </div>
      </div>
    </v-banner>

    <!-- 功能 -->
    <v-card flat class="py-6 position-r">
      <v-card-title class="justify-center  font-size-20 color-#333333 pa-0 line-height-28">功能</v-card-title>
      <v-tabs v-model="tab" background-color="transparent" class="xs-tab" >
        <v-tab v-for="(v, k) in list" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === list.length - 1 ? 'mr-5':'']"
               style="min-width: unset">
          <div class="font-size-16 letter-spacing">{{ v.title }}</div>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-5" v-model="tab" style="border-radius: 10px;box-shadow: 0px 0px 18px 0px rgba(0, 92, 193, 0.16);">
        <v-tab-item v-for="(v, k) in list" :key="k" :transition="false">
          <v-card  color="white" class="pa-5">
            <v-img :src="v.img" width="100%" min-height="200" contain></v-img>
            <v-card-title class="pa-0 mt-4 font-size-18 ">{{v.h2}}</v-card-title>
            <v-card-text class="pa-0 mt-4 font-size-16 " v-text="v.sub" />
            <v-card-text class="pa-0 mt-2 font-size-13 color-666" v-text="v.content" v-html="v.content"/>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
      <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
    </v-card>
    <!-- 方案优势 -->
    <v-card class="px-4 py-6" flat color="#F5F8FC">
      <v-card-title class="justify-center  font-size-20 pa-0 line-height-28">方案优势</v-card-title>
      <div class="mt-7">
        <v-row v-for="(row,rowIndex) in Vorteil" :key="rowIndex">
          <v-card class="pa-3 d-flex h5-card mb-3" min-height="90" width="100%" flat>
            <v-img :src="row.img" max-width="65" contain position="center"></v-img>
            <div class="ml-4">
              <v-card-title class="pa-0 mt-3 font-size-16  line-height-20">
                {{row.title}}
              </v-card-title>
              <v-card-text class="pa-0 mt-2 font-size-13 color-666 line-height-20">
                {{row.text}}
              </v-card-text>
            </div>
          </v-card>
        </v-row>
      </div>
    </v-card>

    <!-- 案例 -->
    <!-- <v-card class="warps warp4 pt-2">
      <v-card-title class="justify-center  font-size-20 color-333 pa-0 mt-6 mb-6 line-height-28">案例</v-card-title>
      <v-row class="pa-0 px-2" style="margin:0 auto;width:100%;">
          <v-col v-for="(info,infoIndex) in anli" :key="infoIndex" :cols='6' class="d-flex justify-center">
             <v-card flat class="h5-card item-card" width="100%" @click="goCaseDetail(info.id)" style="border-radius:5px;">
                <v-img max-width="100%" contain :src="info.coverImg"></v-img>
                <div class="text-box px-3">
                    <v-card-text class="sizeStyle pa-0 mt-3 mb-1 title" v-text="info.itemTitle"></v-card-text>
                    
                </div>
              </v-card>
          </v-col>
      </v-row>
    </v-card> -->

    <!-- 展示终端 -->
    <v-card class="justify-center wap3 pb-3" :flat="true" style="padding: 10px 0 0px;background: #FBFBFB;">
      <v-card-title class="justify-center  font-size-20 color-333 pa-0 mt-6 mb-6 line-height-28">展示终端</v-card-title>
      <div class="con">
        <v-row>
          <v-col
            v-for="(n,index) in cards"
            :key="index"
            class="d-flex child-flex"
            cols="12"
          >
            <div class="box-img" :style="{backgroundImage: 'url('+n.src+')'}">
              <div class="tit">
                <div class="center">
                  <p class="p1">{{ n.tit }}</p> 
                  <p class="p2">{{ n.content }}</p>     
                </div>         
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>

   </div>
</template>

<script>
import textData from '@/static/textData.js' // 合作案例
import qs from 'qs'
export default {
  name: "OnlineRetailers",
  data(){
    return{
      model: 0,
      bannerLoad: false,
      imgLoad: false,
      windowHight: window.innerHeight/2,
      tab:null,
      list:[
        {title:"3D沙盘",h2:'3D沙盘',img:'https://h5.ophyer.cn/official_website/other/RealEstate01.png',content:'俯瞰楼盘四周，山川河流、交通商业、医院人文等周边资源<br/>一目了然'},
        {title:"VR样板间",h2:'VR样板间',img:'https://h5.ophyer.cn/official_website/other/RealEstate02.png',content:'720度立体展示，带给用户沉浸式体验，提升用户场景内<br/>停留时间，让用户触达产品的更多细节'},
        {title:"VR直播",h2:'VR直播',img:'https://h5.ophyer.cn/official_website/other/RealEstate03.png',content:'实景复刻，语音互动，打破空间距离的限制，让导览<br/>更高效'},
        {title:"户型品鉴",h2:'户型品鉴',img:'https://h5.ophyer.cn/official_website/other/RealEstate04.png',content:'详细介绍房屋每一个区域，充分展示房屋的优势，让用<br/>户快速获取一切信息'},
      ],
      Vorteil:[
        {
          img: require('../../../public/img/icons/house/house2-1.png'),
          title: '突破空间限制',
          text: '无需到达现场即可浏览'
        },
        {
          img: require('../../../public/img/icons/house/house2-2.png'),
          title: '直观展示',
          text: 'VR样板间让看房更直观'
        },
        {
          img: require('../../../public/img/icons/house/house2-3.png'),
          title: '互动性强',
          text: 'VR直播增强线上看房的互动性'
        },
      ],
      advantage:[
        { title: '国家政策扶持', src: 'https://h5.ophyer.cn/official_website/other/learnYS1.png', content:'匹配教育部对虚拟现实专业的建设要求'},
        { title: '大平台技术支撑', src: 'https://h5.ophyer.cn/official_website/other/learnYS2.png', content:'虚拟现实应用技术引领者，打造VR<br/>虚拟现实教学平台'},
        { title: '教学资源丰富', src: 'https://h5.ophyer.cn/official_website/other/learnYS3.png', content:'对接主流教育行业，拥有各专业丰富的<br/>教学资源'},
      ],
      //展示终端
      cards: [
        { id:1,src: 'https://h5.ophyer.cn/official_website/other/LavOccupation_01Terminal_01.png', tit:'手机电脑端',content:'可以在线上转发传播'},
        { id:2,src: 'https://h5.ophyer.cn/official_website/other/LavOccupation_01Terminal_02.png', tit:'飞天感知交互台',content:'智能触控技术为核心的智能交互桌面式显示终'},
        { id:3,src: 'https://h5.ophyer.cn/official_website/other/LavOccupation_01Terminal_03.png', tit:'飞天立体交互墙',content:'在小间距LED的基础上增加交互装置 打造了替代投影教学方案的全新沉浸式教学系统'},
        { id:4,src: 'https://h5.ophyer.cn/official_website/other/LavOccupation_01Terminal_04.png', tit:'飞天沉浸互动屋',content:'V-CAVE投影系统是由3个面以上硬质背投影墙组成是一套专门为虚拟仿真实验而打造的沉浸系统'},
        { id:5,src: 'https://h5.ophyer.cn/official_website/other/LavOccupation_01Terminal_05.png', tit:'飞天立体交互桌',content:'基于全息台用户可以在自由的空间内对模型进行多角度观察，并进行分析、修改、操控等交互'},
        { id:6,src: 'https://h5.ophyer.cn/official_website/other/LavOccupation_01Terminal_06.png', tit:'全息沙盘',content:'采用全息投影、多人互动传感等技术，在沙盘上呈现TB级别高精度三维模型。用户可以对沙盘上的模型进行编辑设计、改变颜色、移动缩放等操作'},
      ],
     anli:[textData.anli.dongmanjiaju,textData.anli.wenzhouyuerongfu],
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang='scss'>

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.public-title{
  height: 0;
  margin: 65px auto 65px;
}
.banner_title{
  font-size: 60px;
  //font-family:$bold-font-family;
  color: #FFFFFF !important;
  // padding-top: 21%;
}
.scence_text{
  color: #FFFFFF !important;
  line-height: 26px !important;
}
.banner_span{
  //font-family: $normal-font-family;
  color: #2672FF !important;
  font-size: 20px;
}
::v-deep .v-banner__wrapper{
  padding: 0 !important;
}
.first_card{
  margin-top: 80px;
}
.containerStyle{
  margin-top: 80px;
  .v-card__title{
    line-height: 26px !important;
  }
  .borderimg{
    width:600px;
    height:300px;
    img{
      border-radius: 10px;
      float: right;
    }
  }
  .borderimg_two{
    width:600px;
    height:300px;
    img{
      border-radius: 10px;
      float: left;
    }
  }
  .part-one{
    margin-bottom: 21px;
    .text-wrap{
      width: 600px;
      .con_tit{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-top:84px
      }
      .con_text{
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 26px;
      }
      .banner-btn {
        margin-top: 50px;
        width: 160px;
        height: 50px;
        background-color: #0568FD;
        border-radius: 25px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.titleh1{
  font-size: 80px;
  //font-family: $bold-font-family;
  line-height: 26px !important;
}
.spantitle{
  font-size: 24px;
  //font-family: $body-font-family;
  line-height: 26px !important;
}
.suntitle{
  font-size: 30px;
  //font-family: $bold-font-family;
  // font-weight: bold;
  font-style: italic;
  line-height: 48px !important;
}
.ptitle{
  font-size: 14px;
  font-family: $body-font-family;
  // font-weight: 400;
  line-height: 26px !important;
}

::v-deep .v-carousel__controls{
  display: none !important;
}
.item-card {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1);
  border-radius: 8px;
  &::after {
      content: "";
      background-image: url("../../assets/images/home/case-icon.png");
      background-size: 100%;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 16px;
      right: 10px;
  }
  transition: 0.3s;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
  &:focus{
     display: none;
  }
}
.wap_case{
  background: #FBFBFB;
  padding: 10px 0;
  margin-top: 40px;
  .public-title{
    margin: 45px auto 65px !important;
  }
}
.warp {
  margin: 83px auto 0;
  text-align: center;
}
.warp1 {
  .con {
    width: 1080px;
    margin: 20px auto 0;
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    img {
      width: 120px;
      height: 120px;
    }
  }
  .tip {
    width: 266px;
    opacity: 0.6;
  }
}
.warp2 {
  .box {
    width: 1024px;
    margin: 50px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 210px;
      border-radius: 30px;
      object-fit: cover
    }
  }
  .right {
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    padding-top: 30px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .sub{
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
      margin-top: 16px;
    }
    .line{
      width: 68px;
      height: 4px;
      background: #000000;
      margin-top: 5px;
    }
    .tip {
      margin: 10px 0;
      font-size: 16px !important;
      color: rgb(102, 102, 102) !important;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
    }
  }
}
.img_title {
    // width: 266px;
    margin: 0 auto;
    text-align: center;
    .theme--light.v-image {
      margin: 0px auto 30px;
      border-radius: 10px;
      width:400px ;
      height: 260px;
      object-fit: cover;
    }
    h2 {
      margin-top: 10px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-top: 5px;
      padding: 0px 45px
    }
}
.advantage_card{
  transition: 0.3s;
  width: 300px;
  height: 260px;
  margin: 0 auto;
  &:hover {
      transform: scale(1.04);
      // box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
    }
}
.wap3{
  width: 1200px;
  margin: 0 auto;
  .public-title {
    margin-top: 57px !important;
  }
.con {
    margin: 25px;
    .box-img {
      width: 300px;
      height: 210px;
      background-size: cover;
      overflow: hidden;
      transition: all 0.3s ease-in;
      border-radius: 10px;
      .tit{
         background-color: rgba(25, 26, 27, 0.5);
         width: 100%;
         height: 100%;
         position: relative;
         color: #fff;
         font-size: 24px;
         display: flex;
         justify-content: center;
         align-items: center;
      }
      .center{
         position: absolute;
         padding: 12px;
         text-align: center;
         .p1{
           font-size: 18px;
           font-family: SourceHanSansCN-Bold, SourceHanSansCN;
           color: #FFFFFF;
           line-height: 27px;
         }
         .p2{
           font-size: 14px;
           font-family: SourceHanSansCN-Regular, SourceHanSansCN;
           font-weight: 400;
           color: #FFFFFF;
           line-height: 16px;
         }
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
</style>